import { render, staticRenderFns } from "./bannerEditor.vue?vue&type=template&id=06f601f7&scoped=true&"
import script from "./bannerEditor.vue?vue&type=script&lang=js&"
export * from "./bannerEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f601f7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VBtn,VProgressLinear})
